.react-calendar-heatmap .color-scale-0 { fill: #EBEDF0; }
.react-calendar-heatmap .color-scale-1 { fill: #C6E48B; }
.react-calendar-heatmap .color-scale-2 { fill: #7BC96F; }
.react-calendar-heatmap .color-scale-3 { fill: #239A3B; }
.react-calendar-heatmap .color-scale-4 { fill: #196127; }
.divbody{
    margin: 0;
    padding: 0;
    background-color: #EBEDF0;
}
.divcomcent{
    margin: 0 150px;
    padding: 20px 50px;
    background-color: #ffffff;
    min-height: 100vh;
}
.userclass{
    text-align: center;
}
/* 超小设备 (手机, 600px 以下屏幕设备) */
@media only screen and (max-width: 600px) {
    .divcomcent{
        margin: 0 0;
        padding: 10px 10px;
        background-color: #ffffff;
        min-height: 100vh;
    }
}
