*{margin: 0; padding: 0;}

/* 视图盒子 */
.view-box{position: relative; width: 100vw; height: 100vh; overflow: hidden; background: #00a8ff;}

.saoyisaoimg{
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}
.saoyisaoshixiao{
  width: 200px;
  height: 200px;
  background-color: rgba(255, 255, 255,0.5);
  backdrop-filter:blur(5px);
}
.saoyisaoshixiaotitle{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  font-size: 20px;
}
/* 内容盒子 */
.content-boxlogin{
  position: absolute; 
  top: 50%; 
  left: 50%; 
  transform: translate(-50%,-50%); 
  width: 400px; 
  height: auto;
  border-radius: 10px; 
  box-shadow: 1px 1px 20px #666; 
  padding: 30px 50px 20px 50px ;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 999;
}
.login-form-div-logo{
  display: flex;
  vertical-align: middle
}

.footbottom{
  position: absolute; 
  bottom: 40px; 
  width: 100%; 
  text-align: center; 
  color: #666;
}
.headertop{
  position: absolute; 
  top: 40px;
  left: 40px;
  width: 100%;
}
.waterlogin{
  position: absolute;
  transform: translate(-100%,-110%); 
  top: 50%;
  left: 50%;
  width: 300px;
  height: 300px;
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70% ;
  box-shadow: inset 10px 20px 30px rgba(0,0,0,0.5),
              10px 10px 20px rgba(0,0,0,0.3),
              10px 15px 30px rgba(0,0,0,0.05),
              inset -10px -10px 15px rgba(255,255,255,0.8);
  animation: action 3s linear infinite alternate;
  background-color: #00a8ff;
}
.waterlogin::after{
  content: '';
  width: 20px;
  height: 20px;
  position: absolute;
  top: 40px;
  left: 60px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 56% 44% 70% 30% / 64% 30% 70% 36% ;
  z-index: 9999;
}
.waterlogin::before{
  content: '';
  width: 15px;
  height: 15px;
  position: absolute;
  top: 60px;
  left: 40px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 56% 44% 70% 30% / 64% 30% 70% 36% ;
}

@keyframes action{
  25%{
    border-radius: 36% 64% 64% 36% / 30% 30% 70% 70%  ;
  }
  50%{
    border-radius: 43% 57% 58% 42% / 30% 37% 63% 70%   ;
  }
  75%{
    border-radius: 54% 46% 58% 42% / 33% 44% 56% 67%    ;
  }
  100%{
    border-radius: 61% 39% 66% 34% / 29% 53% 47% 71%  ;
  }
}


.loginspinner {
  position: relative;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  /* margin-left: 75px; */
  display: inline-block;
  /* border: red solid 1px; */
 }
 
 .loginspinner span {
  position: absolute;
  top: 50%;
  left: var(--left);
  width: 35px;
  height: 7px;
  background: #ffff;
  animation: dominos 1s ease infinite;
  box-shadow: 2px 2px 3px 0px black;
 }
 
 .loginspinner span:nth-child(1) {
  --left: 80px;
  animation-delay: 0.125s;
 }
 
 .loginspinner span:nth-child(2) {
  --left: 70px;
  animation-delay: 0.3s;
 }
 
 .loginspinner span:nth-child(3) {
  left: 60px;
  animation-delay: 0.425s;
 }
 
 .loginspinner span:nth-child(4) {
  animation-delay: 0.54s;
  left: 50px;
 }
 
 .loginspinner span:nth-child(5) {
  animation-delay: 0.665s;
  left: 40px;
 }
 
 .loginspinner span:nth-child(6) {
  animation-delay: 0.79s;
  left: 30px;
 }
 
 .loginspinner span:nth-child(7) {
  animation-delay: 0.915s;
  left: 20px;
 }
 
 .loginspinner span:nth-child(8) {
  left: 10px;
 }
 
 @keyframes dominos {
  50% {
   opacity: 0.7;
  }
 
  75% {
   -webkit-transform: rotate(90deg);
   transform: rotate(90deg);
  }
 
  80% {
   opacity: 1;
  }
 }
 

#webtitle{
  display: inline-block;
  margin-left: 60px;
  margin-top: 10px;
}

